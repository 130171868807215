<template>
  <CanBePaused :paused="paused">
    <v-container class="fill-height">
      <v-row justify="center">
        <v-col
          cols="11"
          sm="10"
          md="9"
          xl="6"
          style="display: flex; flex-direction: column"
        >
          <CustomerPage
            v-if="!loading"
            :unit_details="unit_details"
            :new_layout="true"
          >
            <v-row v-if="!valid_for_payment" class="mt-5" justify="center">
              <div v-if="not_found">
                <h3 data-test="not-found-text">
                  {{ $t('Transaction not found') }}
                </h3>
              </div>
              <v-col v-else cols="12" md="8">
                <v-card
                  v-if="invalid_state_details"
                  class="pa-4 text-center rm-box-shadow"
                >
                  <h3>{{ $t(invalid_state_details.state) }}</h3>
                  <p>{{ $t(invalid_state_details.message) }}</p>
                </v-card>
              </v-col>
            </v-row>

            <div
              class="payment-request flex-col"
              v-show="valid_for_payment"
              style="background: white !important"
            >
              <div class="ottu-panels">
                <v-expansion-panels multiple>
                  <div class="contact-info">
                    <!-- <div class="desktop-heading">{{ $t("Order Summary") }}</div> -->
                    <v-expansion-panel
                      v-if="!showContent"
                      class="contact-information"
                    >
                      <v-expansion-panel-title>
                        {{ $t('Contact Information') }}
                      </v-expansion-panel-title>
                      <v-expansion-panel-text eager>
                        <div v-if="showFromforPaymentPage" class="from__div">
                          <h2>{{ $t('From') }}</h2>
                          <ul
                            v-if="transaction_sender"
                            class="from__ul"
                            data-test="from-sender-details"
                          >
                            <li
                              v-for="paymentFrom in this.transaction_details
                                .sender"
                              v-bind:key="paymentFrom.order"
                              class="break-word"
                            >
                              <span v-if="paymentFrom.value">{{
                                paymentFrom.label
                              }}</span>
                              <span>{{ paymentFrom.value }}</span>
                            </li>
                          </ul>
                          <ul
                            v-else
                            class="from__ul"
                            data-test="from-unit-details"
                          >
                            <li class="break-word">
                              <span>{{
                                this.$i18n.locale === 'ar' &&
                                unit_details.title_ar
                                  ? unit_details.title_ar
                                  : unit_details.title_en
                              }}</span>
                            </li>
                            <li class="break-word">
                              <span>{{ website }}</span>
                            </li>
                          </ul>
                        </div>
                        <div v-if="transaction_details_to" class="to__div">
                          <h2>{{ $t('To') }}</h2>
                          <ul class="to__ul" data-test="to-details">
                            <li
                              v-if="getToDetails.toCustomerName"
                              class="break-word"
                            >
                              <span>{{
                                getToDetails.toCustomerNameLabel
                              }}</span>
                              <span>{{ getToDetails.toCustomerName }}</span>
                            </li>
                            <li
                              :key="index"
                              v-for="(
                                paymentTo, index
                              ) in getToDetails.remainingToInformation"
                              class="break-word"
                            >
                              <span v-if="paymentTo.value">{{
                                paymentTo.label
                              }}</span>
                              <span
                                :class="{
                                  'break-word':
                                    paymentTo.label.includes('email'),
                                  phone: paymentTo.label.includes('phone'),
                                }"
                                >{{ paymentTo.value }}</span
                              >
                            </li>
                          </ul>
                        </div>
                      </v-expansion-panel-text>
                    </v-expansion-panel>
                  </div>

                  <div v-if="!showContent" class="order-desc">
                    <order-description
                      v-if="transaction_details.order_description"
                      :transaction_details="transaction_details"
                      isCheckout
                      :attempt="false"
                      :is_customer_payment="is_customer_payment"
                    />
                  </div>
                </v-expansion-panels>
              </div>

              <div v-if="!showContent">
                <v-row :dense="true">
                  <v-col
                    v-if="attachment_url"
                    cols="12"
                    :md="invoice_pdf_url && 6"
                  >
                    <v-btn
                      class="download__btn"
                      data-test="download-attachment"
                      @click="downloadAttachment(attachment_url)"
                    >
                      {{ $t('Download Attachment') }}
                      <img
                        src="../assets/download.svg"
                        alt="download attachment"
                      />
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="invoice_pdf_url"
                    cols="12"
                    :md="attachment_url && 6"
                  >
                    <v-btn
                      color="#F77D1A"
                      class="download__btn"
                      data-test="download-invoice"
                      @click="downloadAttachment(invoice_pdf_url)"
                    >
                      {{ $t('View Invoice') }}
                      <img src="../assets/download.svg" alt="" />
                    </v-btn>
                  </v-col>
                </v-row>
              </div>

              <div id="checkout"></div>
              <v-row justify="center" class="terms__row mg_row-0">
                <v-row class="justify-center mg-0" v-if="valid_for_payment">
                  <div class="terms" v-if="terms && !showContent">
                    {{ $t('By clicking Pay button you are accepting our') }}
                    <v-dialog v-model="dialog" width="600">
                      <template v-slot:activator="{ props }">
                        <a href="#terms" class="terms__link" v-bind="props">{{
                          $t('Terms & Conditions')
                        }}</a>
                      </template>
                      <v-card class="terms-conditions__card rounded-0">
                        <v-card-title>
                          <span class="headline">
                            {{ $t('Terms & Conditions') }}
                            <v-btn
                              flat
                              icon="mdi-close"
                              @click="dialog = false"
                            />
                          </span>
                        </v-card-title>
                        <v-card-text
                          style="text-align: center"
                          v-html="terms_text"
                        />
                        <v-card-actions>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </div>
                </v-row>
              </v-row>
            </div>
            <section class="page-content">
              <div v-if="!valid_for_payment" style="min-height: 300px">
                <div class="first-row2">
                  <img :src="failed" alt="" class="success-logo" />
                  <p
                    class="red-clr text-center status-text"
                    v-if="not_found"
                    data-test="expired-text"
                  >
                    {{ $t('This link may have expired!') }}
                  </p>
                  <p
                    class="red-clr text-center status-text"
                    v-if="invalid_state_details"
                  >
                    {{ $t(invalid_state_details.message) }}
                  </p>
                </div>
              </div>
            </section>
          </CustomerPage>
        </v-col>
      </v-row>
    </v-container>
    <section v-if="loading" class="page-content">
      <v-container fluid>
        <v-skeleton-loader type="image" :height="700"></v-skeleton-loader>
      </v-container>
    </section>
  </CanBePaused>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'pinia';

import descdate from '../../public/descdate.jpg';
import descid from '../../public/descid.jpg';
import downloadrcpt from '../../public/downloadrcpt.jpg';
import failed from '../../public/failed.png';
import orderDescription from '../../public/order-description.png';
import OrderDescription from '../components/OrderDescription.vue';
import { getPublicApiKey } from '../services/common';

import PymtPageMixin from './PymtPageMixin';

import CanBePaused from '@/components/CanBePaused.vue';
import CustomerPage from '@/components/CustomerPage.vue';
import checkUrl from '@/lib/validateURL';
import { useGlobalVariableStore } from '@/store/index';

import '../assets/css/paymentNewStyles.css';
import '../assets/css/paymentStyles.css';
import '../assets/style.css';
import '../assets/rtl.css';

export default {
  name: 'CheckoutPage',
  components: {
    CanBePaused,
    CustomerPage,
    OrderDescription,
  },
  mixins: [PymtPageMixin],
  data: () => ({
    gatewayApiKey: '',
    welcome_dialog: true,
    descdate: descdate,
    descid: descid,
    failed: failed,
    downloadrcpt: downloadrcpt,
    orderDescription: orderDescription,
    confirm_dialog: false,
    showRedirectPopup: false,
    customer_info: {},

    isConfirmed: false,
    multi_steps_config: {},
    showContent: true,

    areaOptions: [],
    isCheckout: true,
    typeOptions: ['House', 'Apartment', 'Office'],

    attachment_url: undefined,
    invoice_pdf_url: undefined,
    merchant_details: {},

    unit_details: {},
    terms: false,
    currency: {},
    extra: {},
    pay_choices: [],
    values: {
      amount: undefined,
    },

    valid_for_payment: false,
    is_customer_payment: true,
    has_editable_amount: false,
    invalid_state_details: undefined,

    paused: false,
    not_found: false,
    remarks: '',
    expiration_time: '',
    additional_phone_number: false,
    show_disclaimer: false,
    disclaimer_text: '',
    page_title: '',
    location_query: {
      area: '',
      country: '',
      block: '',
      str: '',
    },
    location_mode: false,
    is_location_changes: false,
    showCustomAddress: false,
    transaction_details: {
      bottom: [],
      to: [],
      order_description: [],
    },
    custom_address_validation: true,
    dialog: false,
    selectedPayChoice: {},
    best: '',

    btn_loading: false,
    loading: true,
    custom_fields: {},
    terms_text: '',
  }),
  async mounted() {
    const _this = this;
    _this.getPageTitle();
    await _this.fetchPublicApiKey();
    await _this.getTransaction();
  },
  watch: {
    '$i18n.locale': function () {
      this.getTransaction();
    },
    'values.amount': function (new_value, old_value) {
      if (old_value === undefined || new_value === old_value) {
        return;
      }
      const session_id = this.$route.params.session_id;
      const amount = this.values.amount;
      this.debounceGetPayChoices(session_id, { amount }, true);
    },
    'customer_info.area': function (val) {
      let { name } = this.areaOptions.find((e) => e.name == val) || {};
      this.location_query.area = name;
      this.location_query.country = 'kuwait';
      this.$refs.map?.searchLocation(this.location_query);
    },
    'customer_info.block': function (val) {
      this.location_query.block = 'Block ' + val;
      this.$refs.map.searchLocation(this.location_query);
    },
    'customer_info.street': function (val) {
      this.location_query.str = val + ' St';
      this.$refs.map.searchLocation(this.location_query);
    },
    '$route.query': function () {
      this.getPageTitle();
    },
  },
  created() {
    if (this.getCheckoutSDKLink) {
      var v2_link = this.getCheckoutSDKLink.replace('v1', 'v2');
      var script = document.createElement('script');
      script.src = v2_link;
      script.type = 'application/javascript';
      script.setAttribute('data-error', 'errorCallback');
      script.setAttribute('data-success', 'successCallback');
      script.setAttribute('data-cancel', 'cancelCallback');
      script.setAttribute('data-beforepayment', 'beforePayment');
      (
        document.getElementsByTagName('head')[0] || document.documentElement
      ).appendChild(script);
    }
    this.showContent = this.$route.query['chd-only'] === 'True' ? true : false;
  },
  computed: {
    transaction_sender() {
      return this.isNotEmptyArray(this.transaction_details.sender);
    },
    ...mapState(useGlobalVariableStore, [
      'showFromforPaymentPage',
      'getCheckoutSDKLink',
      'getDisplayRejectButton',
      'getPayButtonText',
    ]),
    transaction_details_to() {
      return this.isNotEmptyArray(this.transaction_details.to);
    },
    website() {
      return this.unit_details.website;
    },
    validation_rules() {
      return [(v) => !!v || this.$t('This field is required')];
    },
  },
  methods: {
    isNotEmptyArray(arr) {
      return Array.isArray(arr) && arr.length;
    },
    downloadAttachment(url) {
      let atag = document.createElement('a');
      atag.setAttribute('download', 'download');
      atag.setAttribute('href', url);
      atag.click();
    },
    getPageTitle() {
      this.page_title = this.$t('Payment Request Details');
    },
    sortArray(arr) {
      return arr.sort((a, b) => {
        return a.order - b.order;
      });
    },
    async fetchPublicApiKey() {
      try {
        const response = await getPublicApiKey();

        this.gatewayApiKey = response.data.key;
      } catch (error) {
        console.log('ERROR:: error while fetching public api key', error);
      }
    },
    async getTransaction() {
      this.loading = true;

      try {
        const data = await this.$processReq(
          this.getTransactionData(this.$route.params.session_id),
          false,
        );
        if (data.next_url) {
          return (window.location.href = data.next_url);
        }

        const _this = this;
        let checkoutInterval = setInterval(function () {
          if (typeof Checkout != 'undefined') {
            _this.initCheckoutGateway();
            clearInterval(checkoutInterval);
          }
        }, 500);
        if (data.pay_choices) this.getSelectedPayChoice(data.pay_choices);
        const {
          amount,
          currency = {},
          extra = {},
          merchant_details,
          unit_details,
          show_terms,
          terms_text,
          pay_choices,
          details,
          valid_for_payment,
          has_editable_amount,
          attachment_url,
          invoice_pdf_url,
          remarks,
          expiration_time,
          country_code,
          additional_phone_number,
          show_disclaimer,
          disclaimer_text,
          sdk_setup_preload_payload,
        } = data;
        Object.assign(this.$data, {
          currency,
          extra,
          merchant_details,
          unit_details: unit_details || {},
          terms: show_terms,
          terms_text,
          pay_choices,
          invalid_state_details: details,
          valid_for_payment,
          has_editable_amount,
          attachment_url,
          invoice_pdf_url,
          remarks,
          expiration_time,
          additional_phone_number,
          show_disclaimer,
          disclaimer_text,
          values: { ...this.$data.values, amount },
          country_code,
          sdk_setup_preload_payload: sdk_setup_preload_payload || {},
        });

        if (extra) this.getFieLdsHistory();

        this.transaction_details = {
          ...data,
          sender: this.sortArray(data.sender || []),
          to: this.sortArray(data.to || []),
          order_description: data.order_description || [],
        };
      } catch (err) {
        if (err.response) {
          if (err.response.status === 423) {
            this.paused = true;
          } else if (err.response.status === 404) {
            this.valid_for_payment = false;
            this.not_found = true;
          } else if (err.response.status === 500) {
            this.valid_for_payment = false;
            this.not_found = true;
          }
        } else if (err.cause === 302) {
          this.valid_for_payment = false;
          this.not_found = true;
        }
        console.log('Error:', err);
      } finally {
        this.loading = false;
      }

      const unfreezeBasket = () => {
        console.log('Basket Unfreezed!');
      };

      window.errorCallback = function (error) {
        let validFormsOfPayments = ['token_pay', 'redirect'];
        if (
          validFormsOfPayments.includes(error.form_of_payment) ||
          error.challenge_occurred
        ) {
          const message =
            'Oops, something went wrong. Refresh the page and try again.';
          window.Checkout.showPopup('error', error.message || message);
        }
        console.log('Error callback', error);
        unfreezeBasket();
      };

      window.successCallback = function (success) {
        window.location.href = success.redirect_url;
      };

      window.cancelCallback = function (cancel) {
        // eslint-disable-next-line no-undef
        if (
          cancel.payment_gateway_info &&
          cancel.payment_gateway_info.pg_name === 'kpay'
        ) {
          window.Checkout.showPopup(
            'error',
            '',
            cancel.payment_gateway_info.pg_response,
          );
        } else if (
          cancel.form_of_payment == 'token_pay' ||
          cancel.challenge_occurred
        ) {
          const message =
            'Oops, something went wrong. Refresh the page and try again.';
          window.Checkout.showPopup('error', cancel.message || message);
        } else if (
          cancel.redirect_url &&
          checkUrl(cancel.redirect_url) &&
          cancel.status.toLowerCase() === 'canceled'
        ) {
          window.location.href = cancel.redirect_url;
        }
        console.log('Cancel callback', cancel);
        unfreezeBasket();
      };

      window.beforePayment = function (data) {
        return new Promise((resolve) => {
          //Freeze the basket before payment process starts
          if (data && data.redirect_url) {
            window.Checkout.showPopup(
              'redirect',
              data.message || 'Redirecting to the payment page',
              null,
            );
          }
          resolve();
          console.log('BeforePayment Hook', data);
        });
      };
    },
    getFieLdsHistory() {
      let i = 0;
      for (const [key, value] of Object.entries(this.extra)) {
        if (key != 'full_name' && value && i < 1) {
          this.showRedirectPopup = true;
          this.welcome_dialog = false;
          i++;
        }
        if (
          key == 'address' &&
          !['Home', 'Work', 'Chalet', 'Farm'].includes(value)
        ) {
          this.customer_info.address = 'Custom name';
          this.customer_info.custom_address = value;
          this.addresChange('Custom name');
          this.checkCustomAdress(value);
        } else this.customer_info[key] = value;
      }
    },
    initCheckoutGateway() {
      const merchant = window.location.host.includes('localhost')
        ? 'delta.ottu.com'
        : window.location.host;

      let payButtonText = undefined;
      try {
        payButtonText = JSON.parse(this.getPayButtonText);
      } catch (err) {
        console.log('Error parsing pay button text: ', err);
      }
      // eslint-disable-next-line no-undef
      Checkout.init({
        selector: 'checkout',
        merchant_id: merchant,
        session_id: this.$route.params.session_id,
        apiKey: this.gatewayApiKey,
        lang: this.$i18n.locale,
        displayMode: 'grid',
        setupPreload: this.$data.sdk_setup_preload_payload,
        displayRejectButton: this.getDisplayRejectButton,
        payButtonText,
      });
    },
    addresChange(value) {
      if (value == 'Custom name') {
        this.showCustomAddress = true;
        this.checkCustomAdress(this.customer_info.custom_address);
      } else {
        this.showCustomAddress = false;
        this.checkCustomAdress('');
      }
    },
    checkCustomAdress(val) {
      if (this.showCustomAddress && !val)
        this.$set(this.$data, 'custom_address_validation', false);
      else this.$set(this.$data, 'custom_address_validation', true);
    },
    getSelectedPayChoice(choices) {
      if (choices.length === 1) {
        this.selectedPayChoice = choices[0];
      } else {
        let filteredGateways = choices.filter((e) => e.fee);
        if (filteredGateways.length) {
          let knet = choices.filter((e) => e.gateway === 'KPay');
          if (knet.length) {
            this.selectedPayChoice = knet[0];
            this.best = knet[0].value;
          } else {
            let min = Math.min(...filteredGateways.map((f) => f.fee));
            filteredGateways.map((minimumFees) => {
              if (Number(minimumFees.fee) === min) {
                return (this.selectedPayChoice = minimumFees);
              }
            });
          }
        } else {
          let choice = choices.find((e) => e.gateway === 'KPay');
          this.selectedPayChoice = choice ? choice : choices[0];
        }
      }
    },
  },
};
</script>
<style scoped>
@media (max-width: 768px) {
  .phone {
    font-size: 13px;
  }
}

.v-dialog {
  border-radius: 15px;
}

.v-btn:hover:before,
.v-btn:focus:before {
  display: none !important;
}

.border-top {
  border-top: 1px solid #e0e0e0;
  padding-top: 15px;
}

.accent--text >>> label:before {
  background-image: url('~@/assets/radio-checked.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: transparent;
}

.v-expansion-panel-title:first-child {
  border-top: 1px solid #e0e0e0 !important;
}

.v-expansion-panel-title.v-expansion-panel-title--active {
  border-color: #03a154;
}

.v-expansion-panel-title--active:first-child {
  border-top: 1px solid #03a154 !important;
}

.v-card__title {
  background-color: transparent !important;
}

.v-card__actions {
  display: flex;
  justify-content: center;
  gap: 25px;
}

.v-card__actions >>> .v-btn {
  width: 137.5px;
  height: 40px;
  border-radius: 25px;
  text-transform: capitalize !important;
}

.v-card__actions >>> .v-btn:first-child {
  font-family: 'SF PRO MEDIUM';
  background-color: white;
  color: #302f37;
  border: 1px solid #302f37;
}

.v-expansion-panel-title--active >>> .toggle-accordion {
  transform: translateY(-50%) rotate(90deg);
  transition: transform 0.3s ease-in-out;
}

.v-input--is-focused >>> .v-label {
  color: #f77d1a !important;
}

@media (max-width: 768px) {
  .d-flex .black {
    stroke: rgba(0, 0, 0, 0.87) !important;
  }
}

.red-clr {
  color: #e94637;
}

.success-logo {
  display: block;
  width: 128px;
  margin: 0 auto;
}

.status-text {
  font-size: 25px;
  margin-top: 15px;
  display: block;
  text-align: center;
}
</style>
