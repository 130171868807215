/*
URL Validation method as referenced here:
https://snyk.io/blog/secure-javascript-url-validation/
*/

export default function checkUrl(string) {
  let givenURL;
  try {
    givenURL = new URL(string);
  } catch (error) {
    return false;
  }
  return givenURL.protocol === 'https:';
}
