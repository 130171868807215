<template>
  <div>
    <div style="margin-top: 100px"></div>
    <v-footer class="ottu-footer" padless dark>
      <div class="social-icons" data-test="social-icons">
        <a v-if="facebook_url" :href="facebook_url" class="social__link">
          <font-awesome-icon :icon="'fab fa-facebook-f'" />
        </a>
        <a v-if="twitter_url" :href="twitter_url" class="social__link">
          <!-- Font Awesome Twitter-X Logo-->
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="16"
            width="16"
            viewBox="0 0 512 512"
          >
            <path
              d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"
            />
          </svg>
        </a>
        <a v-if="instagram_url" :href="instagram_url" class="social__link">
          <font-awesome-icon :icon="'fab fa-instagram'" />
        </a>
      </div>
    </v-footer>
  </div>
</template>

<script>
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config, library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

config.autoAddCss = false;
library.add(faFacebookF, faInstagram);

export default {
  name: 'PaymentFooter',
  props: {
    twitter_url: String,
    instagram_url: String,
    facebook_url: String,
  },
  components: {
    FontAwesomeIcon,
  },
};
</script>

<style scoped>
body {
  padding-bottom: 30px;
}
.ottu-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
}
</style>
